import { RootService } from "./root-service"
import jsCookie from "js-cookie"
import { TokenApiList } from "./token-service"

async function handleTokenHook(config) {
  if (config.headers["standardized-token"] === undefined) {
    const token = jsCookie.get("token")

    if (token && token !== "undefined") {
      config.headers["standardized-token"] = token
    } else {
      const { data } = await TokenApiList.getToken()

      if (data?.data?.token?.token_code && data?.data?.token?.refresh_token) {
        const token = data.data.token.token_code

        config.headers["standardized-token"] = token
      }
    }
  }

  return config
}

async function handleRefreshTokenHook(response, retry) {
  const token = jsCookie.get("token")
  const refreshToken = jsCookie.get("refresh_token")

  if (!token || !refreshToken) {
    return
  }

  if (response.status === 401 || response.status == 502) {
    const { data } = await TokenApiList.getRefreshToken({
      token: token,
      refreshToken: refreshToken,
    })

    if (data?.data?.token?.token_code && data?.data?.token?.refresh_token) {
      return retry()
    }
  }
}

const ClientService = RootService.extend({
  url: "api",
  isClient: true,
})
  .addBeforeRequest(handleTokenHook)
  .addAfterRequest(handleRefreshTokenHook)

export const ClientApiList = {
  getAuthData(body) {
    return ClientService.post("/fs/user/get-auth-data", body)
  },
  getCheckData(body) {
    return ClientService.post("/fs/user/check-data", body)
  },
  getStatusLogin(body) {
    return ClientService.post("/fs/user/login-status", body)
  },
  getHeroBanner(body) {
    const defaultBody = {
      is_branded: body?.is_branded || 0,
      section_name: body?.section_name || "homepage_unbranded",
    }
    return ClientService.post("/fs/banner/hero-banner", defaultBody)
  },
  getSubBanner(body) {
    return ClientService.post("/fs/banner/sub-banner", body)
  },
  getListArticle(body) {
    const defaultBody = {
      is_regular: body?.is_regular || "",
      campaign: body?.campaign || "",
      category: body?.category || "",
      sub_category: body?.sub_category || "",
      is_branded: body?.is_branded || 0,
      format: body?.format || "",
      is_highlight: body?.is_highlight || "",
      pinned: body?.pinned || "",
      page: body?.page || 0,
      length: body?.length || 3,
      is_engagement: body.is_engagement || "",
      string_id: body.string_id || "",
    }
    return ClientService.post("/fs/feed/list", defaultBody)
  },
  getEventList(event, page = 0) {
    const defaultBody = {
      status_event: event,
      is_branded: 1,
      page: page,
      length: 10,
    }
    return ClientService.post("/fs/event/list", defaultBody)
  },
  getExclusiveList(search, page = 0) {
    const defaultBody = {
      search,
      is_branded: 1,
      page: page,
      length: 10,
    }
    return ClientService.post("/fs/ex/list", defaultBody)
  },
  // getEventDetail(dump, uuid) {
  //   const defaultBody = {
  //     uuid,
  //     is_branded: 1,
  //   };
  //   return ClientService.post('/fs/event/detail', defaultBody);
  // },
  getProfileHeader() {
    const defaultBody = {}
    return ClientService.post("/fs/user/get-profile-header", defaultBody)
  },
  getWordleAnswer(body) {
    return ClientService.post("/fs/simple_engagement/answer_wordle", body)
  },
  getEngagementDetail(body) {
    return ClientService.post("/fs/simple_engagement/engagement_detail", body)
  },
  getPointLogging(body) {
    const defaultBody = {
      content_brand: body.content_brand,
      content_type: body.content_type,
      id: body.id,
      is_regular: body.is_regular,
      progress: body.progress,
    }
    return ClientService.post("/fs/feed/logging", defaultBody)
  },
  getFinalChallenge(body) {
    return ClientService.post("/fs/special/final_challenge", body)
  },
  getProfileData(body) {
    return ClientService.post("/fs/user/get-profile", body)
  },
  getMasterCity() {
    const defaultBody = {}
    // api/fs/user/get-profile
    return ClientService.get("/fs/master/get-master-city", defaultBody)
  },
  getMasterBrands() {
    const defaultBody = {}
    // api/fs/user/get-profile
    return ClientService.post("/fs/master/get-master-brand", defaultBody)
  },
  updateProfileData(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post("/fs/user/update-profile", defaultBody)
  },
  checkPassword(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post("/fs/user/check-password", defaultBody)
  },
  updatePassword(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post("/fs/user/change-password", defaultBody)
  },
  updateAccountSetting(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post("/fs/user/delete-account", defaultBody)
  },
  verifyEmail(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post("/fs/user/send-verify-email", defaultBody)
  },
  updatePhone(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post("/fs/user/update-phone", defaultBody)
  },
  verifyEmailZB(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post("/fs/amild/user/validate-email", defaultBody)
  },
  updateEmail(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post("/fs/user/update-email", defaultBody)
  },
  requestOTP(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post("/fs/user/request-otp", defaultBody)
  },
  requestOTPChannel(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post("/fs/user/get-otp-channel", defaultBody)
  },
  getEngagementIntro(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post(
      "/fs/simple_engagement/engagement_introduction",
      defaultBody
    )
  },
  getEngagementSubmissionDetail(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post(
      "/fs/simple_engagement/engagement_detail",
      defaultBody
    )
  },
  getEngagementPuzzleDetail(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post(
      "/fs/simple_engagement/engagement_detail",
      defaultBody
    )
  },
  getEngagementSubmissionConfirmation(body) {
    const defaultBody = {
      type: "text-submission",
      ...body,
    }
    return ClientService.post(
      "/fs/simple_engagement/engagement_confirmation",
      defaultBody
    )
  },
  getEngagementPuzzleConfirmation(body) {
    const defaultBody = {
      type: "puzzle",
      ...body,
    }
    return ClientService.post(
      "/fs/simple_engagement/engagement_confirmation",
      defaultBody
    )
  },
  getEngagementSubmissionAnswer(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post(
      "/fs/simple_engagement/answer_submission",
      defaultBody
    )
  },
  getEngagementPuzzleAnswer(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post(
      "/fs/simple_engagement/answer_puzzle",
      defaultBody
    )
  },
  getCarouselPoint(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post(
      "/fs/simple_engagement/answer_carousel",
      defaultBody
    )
  },
  getLoyaltyTeaser(body) {
    const defaultBody = {
      ...body,
    }
    return ClientService.post("/fs/user/loyalty-teaser", defaultBody)
  },
  getGameConfirmation(body) {
    return ClientService.post(
      "/fs/simple_engagement/engagement_confirmation",
      body
    )
  },
  getQuizAnswer(body) {
    const defaultBody = {
      engagement_uuid: body.engagement_uuid,
      answer: body.answer,
      order: body.order,
      question_opt: body.question_opt,
    }
    return ClientService.post("/fs/simple_engagement/answer_quiz", defaultBody)
  },
  getSyaratKetentuan() {
    const defaultBody = {
      is_branded: "0",
    }
    return ClientService.post("/fs/footer/syarat-ketentuan", defaultBody)
  },
  getBantuan(body) {
    const defaultBody = {
      is_branded: body.is_branded,
      topic_id: body.topic_id,
      question: body.question,
    }
    return ClientService.post("/fs/footer/bantuan", defaultBody)
  },
  getUserRewardList(body) {
    const defaultBody = {}
    return ClientService.post("/fs/loyalty-reward/list", defaultBody)
  },
  getOnBoarding(body) {
    return ClientService.post("/fs/popup-onboarding", body)
  },
  getPopupGeneral(body) {
    return ClientService.post("/fs/popup-onboarding/general", body)
  },
  getSubmitForm(body) {
    const defaultBody = {
      address: body.address,
      merchandise_id: body.merchandise_id,
    }
    return ClientService.post("/fs/submission-form/submit", defaultBody)
  },
  getStatusClaim(body) {
    const defaultBody = {
      merchandise_id: body.merchandise_id,
    }
    return ClientService.post("/fs/submission-form/check", defaultBody)
  },
  getAnswerFile(body) {
    let formData = new FormData()
    formData.append("engagement_uuid", body.engagement_uuid)
    formData.append("type", body.type)
    formData.append("caption", body.caption)
    formData.append("submission", body.submission)
    formData.append("g-recaptcha-response", body["g-recaptcha-response"])

    body.submission.forEach((element) => {
      formData.append(`submission`, element)
    })

    return ClientService.post(
      "/fs/simple_engagement/answer_submission",
      formData
    )
  },
  postAnswerAudio(body) {
    return ClientService.post("/fs/simple_engagement/answer-audio", body)
  },
  getUserLeaderboard(body) {
    const defaultBody = {}
    return ClientService.post("/fs/leaderboard/point", defaultBody)
  },
  getMGMHistory(body) {
    const defaultBody = {}
    return ClientService.post("/fs/mgm/mgm_history", defaultBody)
  },
  getMGMProgress(body) {
    const defaultBody = {}
    return ClientService.post("/fs/mgm/mgm_progress", defaultBody)
  },
  getMgMLink(body) {
    const defaultBody = {}
    return ClientService.post("/fs/mgm/mgm_referral_code", defaultBody)
  },
  getInteractionUser(body) {
    return ClientService.post("/fs/interaction-user", body)
  },
  getMissionList(body) {
    return ClientService.post("/fs/special/campaign_challenge", body)
  },
  logout(body) {
    return ClientService.post("/fs/user/logout", body)
  },
  getPoint() {
    return ClientService.post("/fs/user/login-point")
  },
  getUserVouchers(body) {
    return ClientService.post("/aldmic/get-voucher-list", body)
  },
  getUserChallenge(body) {
    const defaultBody = {
      is_regular: 1,
      is_branded: 1,
      page: body?.page || 0,
      length: body?.length || 10,
      is_engagement: 1,
    }
    return ClientService.post("/fs/feed/list", defaultBody)
  },
  getMGMLog(body) {
    return ClientService.post("/fs/mgm/mgm_log", body)
  },
  getMGMUpdate(body) {
    return ClientService.post("/fs/mgm/update_mgm_log", body)
  },
  getDetailArticle(body) {
    const defaultBody = {
      string_id: body.string_id || "",
      is_branded: body.is_branded || 0,
      ...body,
    }
    return ClientService.post("/fs/feed/detail", defaultBody)
  },
  getPointUb(body) {
    return ClientService.post("/fs/feed/ub-point", body)
  },
  checkUserPoint(body) {
    return ClientService.post("/fs/user/check-status-point", body)
  },
  updatePopup(body) {
    return ClientService.post("/fs/user/popup", body)
  },
  getGlobalConfig(body) {
    return ClientService.post("/fs/config/global", body)
  },
  getAspaceToken(body) {
    return ClientService.post("/exhibition/get-token", body)
  },
  getAspaceClaim(body) {
    return ClientService.post("/exhibition/get-claim-status", body)
  },
  getUserRank(body) {
    return ClientService.post("/exhibition/get-user-rank", body)
  },
  getLeaderBoard(body) {
    return ClientService.post("/exhibition/get-leaderboard", body)
  },
  getCampaign(body) {
    return ClientService.post("/fs/special/special_list", body)
  },
  getNextChallenge(body) {
    return ClientService.post("/fs/special/next_mission", body)
  },
  getAspaceFeedback(body) {
    return ClientService.post("/exhibition/submit-feedback", body)
  },
  getAboutBenefit(body) {
    return ClientService.post("/fs/about/benefit", body)
  },
  postIframeData(body) {
    return ClientService.post("/ag/log-game-data", body)
  },
  getGallerySubmission(body) {
    return ClientService.post("/fs/gallery_submission/media", body)
  },
  getGalleryAudio(body) {
    return ClientService.post("/fs/gallery_submission/audio", body)
  },
  getGalleryText(body) {
    return ClientService.post("/fs/gallery_submission/text", body)
  },
  getGalleryVote(body) {
    return ClientService.post("/fs/gallery_submission/vote", body)
  },
  setTncEngagement(body) {
    return ClientService.post("/fs/simple_engagement/tnc", body)
  },
  getCampaignWinner(body) {
    return ClientService.post("/fs/special/winner-list", body)
  },
  postCRMExtraPoint(body) {
    return ClientService.post("/fs/user/crm-extra-point", body)
  },
  postOfflineEngagementPoint(body) {
    return ClientService.post("/ag/log-offline-data", body)
  },
  postBridgingLog(body) {
    return ClientService.post("/fs/bridging/log", body)
  },
  getAnswerUGC(body) {
    let formData = new FormData()
    formData.append("engagement_uuid", body.engagement_uuid)
    formData.append("text", body.text)

    // body.submission.forEach((element) => {
    formData.append(`ugc`, body.ugc)
    // })

    return ClientService.post("/fs/simple_engagement/answer_ugc", formData)
  },
  postTrack(body) {
    return ClientService.post("/track", body)
  },
}

export const ClientAllAccessApi = {
  getUrl(body) {
    return ClientService.post("/fs/user/req-url", body)
  },
  getAuthData(body) {
    return ClientService.post("/fs/user/get-auth-data", body)
  },
  logout(body) {
    return ClientService.post("/fs/user/logout", body)
  },
}
