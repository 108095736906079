import ApiService from "./api-service";

export const RootService = new ApiService({
  url: process.env.NEXT_PUBLIC_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const RootServerService = new ApiService({
  url: "http://ecs-api.pmid-mssid-dev/",
  headers: {
    "Content-Type": "application/json",
  },
});
