import { generateRandomString } from "@/helper/utils";
import { RootService } from "./root-service";

export const TokenService = RootService.extend({
  url: `api/token`,
  next: {
    revalidate: 600,
  },
});

export const TokenApiList = {
  getToken: async () => {
    const deviceId = generateRandomString(36);

    const body = {
      name: process.env.NEXT_PUBLIC_NAME,
      secret_key: process.env.NEXT_PUBLIC_SECRET_KEY,
      device_type: process.env.NEXT_PUBLIC_DEVICE_TYPE,
      app_key: process.env.NEXT_PUBLIC_APP_KEY,
      device_id: deviceId,
    };

    return TokenService.post("/get", body);
  },
  getRefreshToken: async ({ token, refreshToken }) => {
    const body = {
      token,
      refresh_token: refreshToken,
    };

    return TokenService.post("/refresh", body);
  },

  checkToken: async ({ token }) => {
    const body = {
      token,
    };
    return TokenService.post("/check", body);
  },
};
