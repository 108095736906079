import { consoleHelper, networkFlow, statusConts } from "@/helper/utils"

export default class ApiService {
  _hooks = {
    beforeRequest: [],
    afterRequest: [],
  }

  constructor({ url, headers, next, credentials, isClient = false }) {
    if (!url || typeof url !== "string")
      throw new Error("API SERVICE : url must be a string")

    this.baseUrl = url
    this.headers = headers
    this.next = next
    this.credentials = credentials
    this.isClient = isClient
  }

  addBeforeRequest(fn) {
    this._hooks.beforeRequest.push(fn)
    return this
  }

  addAfterRequest(fn) {
    this._hooks.afterRequest.push(fn)
    return this
  }

  extend = (config) => {
    return new ApiService({
      url: `${this.baseUrl}${config.url || ""}`,
      headers: {
        ...this.headers,
        ...config.headers,
      },
      next: config.next,
      credentials: config.credentials,
      isClient: config.isClient,
    })
  }

  async _request(method, url, body, config = {}) {
    const reqUrl = `${this.baseUrl}${url}`

    const isFormData = body instanceof FormData

    let reqConfig = {
      method: method || "GET",
      body: isFormData
        ? body
        : method !== "GET"
          ? JSON.stringify(body)
          : undefined,
      headers: {
        ...this.headers,
        ...(config.headers || {}),
      },
      next: config.next || this.next,
      credentials: config.credentials || this.credentials,
    }

    if (isFormData) delete reqConfig.headers["Content-Type"]

    // !this.isClient &&
    consoleHelper(
      statusConts.info,
      { url, ...reqConfig },
      networkFlow.request,
      reqUrl
    )

    const beforeRequest = this._hooks.beforeRequest

    for await (const fn of beforeRequest) {
      reqConfig = await fn(reqConfig, reqUrl)
    }

    let maxRetry = 3
    let retryCount = 0

    try {
      const response = await fetch(reqUrl, reqConfig)

      // !this.isClient &&
      consoleHelper(
        statusConts.info,
        response,
        networkFlow.request,
        `${reqUrl} response`
      )

      const responseData = await response.json()

      // !this.isClient &&
      consoleHelper(
        statusConts.info,
        responseData,
        networkFlow.request,
        `${reqUrl} json`
      )

      const retry = async () => {
        if (retryCount > maxRetry) {
          console.log("max retry reached")
          return
        }

        retryCount++

        return await this._request(method, url, body)
      }

      for await (const fn of this._hooks.afterRequest) {
        const retryResponse = await fn(response, retry)

        if (retryResponse) {
          return retryResponse
        }
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: null,
          error: responseData?.error ?? {},
        }
      }

      return {
        status: response.status,
        statusText: response.statusText,
        data: responseData,
      }
    } catch (error) {
      // console.log(error.message, "error")
      // !this.isClient &&
      consoleHelper(statusConts.error, error, networkFlow.received)
      return {
        status: 500,
        statusText: error.message,
        data: null,
      }
    }
  }

  async get(url, config) {
    return await this._request("GET", url, undefined, config)
  }

  async post(url, data, config) {
    return await this._request("POST", url, data, config)
  }

  async patch(url, data, config) {
    return await this._request("PATCH", url, data, config)
  }

  async put(url, data, config) {
    return await this._request("PUT", url, data, config)
  }

  async delete(url, config) {
    return await this._request("DELETE", url, undefined, config)
  }
}
